import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getEmpresa(id: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type','application/json');

    let params = {
      'idEmpresa': id
    };

    return this.http.post(this.baseUrl + '/empresa', params, {headers: headers});
  }
}
