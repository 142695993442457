import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DownloadService } from '../services/download/download.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  constructor(private router:ActivatedRoute,private downloadService:DownloadService) {}
  loading=true
  ngOnInit() {
    this.downloadService.descargar(this.router.snapshot.params.token).subscribe(
      response => {
        //let blob = new Blob();
        this.loading = true
        let file = new Blob([new Uint8Array(response.data)], { type: 'application/pdf' });
        //var fileURL = URL.createObjectURL(file);
        //window.open(fileURL,"_blank");
        //window.close()
        this.guardar(file)

      },
    );
  }

  async guardar(file){
    await saveAs(file, 'informeCheckerGDPR.pdf');
    this.loading = false
  }
}
