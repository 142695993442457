import { Component, OnInit } from '@angular/core';

import { fundido } from '../animations/animations'
import { Router } from '@angular/router';
import { LanguageService } from '../services/language/language.service';


@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['../styleForm.css'],
  animations: [fundido]
})
export class CompletedComponent implements OnInit {
  language: String;
  textos = null;


  constructor(
    private languageService: LanguageService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.language = sessionStorage.getItem('lang');
    this.languageService.loadMenu(sessionStorage.getItem('lang'),'completado').subscribe(
      result => {
          let resultado = result;
          if(resultado.length!=0){
          this.textos = resultado[0].elementos[0];
          }
      },
    );
  }

  restart(){
    let lang = sessionStorage.getItem('lang');
    let empresa_id = sessionStorage.getItem('empresa_id');
    let empresa_nombre = sessionStorage.getItem('empresa_nombre');
    sessionStorage.clear();
    sessionStorage.setItem('lang',lang);
    sessionStorage.setItem('empresa_id',empresa_id);
    sessionStorage.setItem('empresa_nombre',empresa_nombre);
    this.router.navigate(['questions']);
  }

}
