import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getLanguages(): Observable<any> {
    return this.http.get(this.baseUrl+'/langs');
  }

  loadMenu(lang: string, modulo: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');

    let params = new HttpParams({ fromObject: {
      'lang': lang,
      'modulo': modulo
    }})

    return this.http.get(this.baseUrl+'/loadMenu',{headers: headers, params},);
  }
}
