import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable} from 'rxjs';
import { environment } from '../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class questionAdminService {
constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl;
  getQuestions(language: string): Observable<any> {

    let params = new HttpParams({ fromObject: {
      'lang': language
    }})

    return this.http.get(this.baseUrl + '/all', {params: params});
  }

  getLanguages(): Observable<any> {
    return this.http.get(this.baseUrl+'/langs');
  }
}
