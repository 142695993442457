import { Component, OnInit } from '@angular/core';
import { PanelService } from '../services/panel/panel.service';
import { Router } from '@angular/router';
import { Empresa } from '../_models/empresa';
import { User } from '../_models/user';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/login/authentication.service';
import { FormBuilder  } from '@angular/forms';
import { first } from 'rxjs/operators';



@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {
  public data: [Empresa] = null
  closeResult: string;
  currentUser: User;
  empresa:Empresa
  editEmpresa:Empresa

  /// imagen updload
  fileData: File = null;
  previewUrl:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  base64textString = [];
  ///
  constructor(
    private panelService: PanelService,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,)
    {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.empresa = new Empresa('','','','');
    }


  ngOnInit() {
    this.panelService.getEmpresas().subscribe(
      result => {
            this.data = result

      },
      error => {
      }
    );
  }

  openDeleteModal(content ,i) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      let empresa = this.data[i];
      this.panelService.deleteEmpresa(empresa).subscribe(
        result => {
          this.panelService.getEmpresas().subscribe(
            result => {
                  this.data = result

            },
            error => {
            }
          );

        },
        error => {
          //console.log(error);
        }
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEditModal(content ,i) {
    const idViejo = this.data[i]._id
    this.editEmpresa= new Empresa(this.data[i].nombre,this.data[i].dominio,this.data[i].logo,this.data[i].correo)
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.panelService.updateEmpresa(this.editEmpresa,idViejo,this.base64textString).subscribe(
        result => {
          this.panelService.getEmpresas().subscribe(
            result => {
                  this.data = result
            },
            error => {
            }
          );

        },
        error => {
          //console.log(error);
        }
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  onSubmit(){
    this.panelService.insertEmpresa(this.empresa,this.base64textString)
        .pipe(first())
        .subscribe(
            dt => {
                //this.router.navigate([this.returnUrl]);
              location.reload()
            },
            error => {

            });
    }

onUploadChange(evt: any) {
  const file = evt.target.files[0];

  if (file) {
    const reader = new FileReader();

    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(file);
  }
}
onUploadChangeE(evt: any) {
  const file = evt.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = this.handleReaderLoadede.bind(this);
    reader.readAsBinaryString(file);
  }

}
handleReaderLoadede(e) {
  this.base64textString=[]
  this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  this.editEmpresa.logo=this.base64textString
}
handleReaderLoaded(e) {
  this.base64textString=[]
  this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  this.empresa.logo=this.base64textString
}

}
