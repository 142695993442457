import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType} from '@angular/common/http';
import { Observable} from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Pregunta } from '../../_models/pregunta';
@Injectable({
  providedIn: 'root'
})
export class PanelService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getLogs(empresa): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type','application/json');
    let params = new HttpParams({ fromObject: {
      'idEmpresa': empresa
    }})
    return this.http.get(this.baseUrl + '/admin/summary',{headers: headers , params:params});
  }

  getTotalEmpresas(): Observable<any> {
    return this.http.get(this.baseUrl + '/admin/totalempresas');
  }

  getEmpresas(): Observable<any> {
    return this.http.get(this.baseUrl + '/admin/getempresas');
  }

  deleteEmpresa(empresa): Observable<any> {

    let params = {
      '_id':empresa._id,
      'nombre': empresa.nombre,
      'dominio': empresa.dominio,
      'logo': empresa.logo,
      'correo': empresa.correo,
    };

  let headers = new HttpHeaders().set('Content-Type','application/json');

  return this.http.post(this.baseUrl + '/admin/deleteempresa', params, {headers: headers});
  }

  insertEmpresa(empresa, image): Observable<any> {
    let params = {
      'nombre': empresa.nombre,
      'dominio': empresa.dominio,
      'logo': image,
      'correo': empresa.correo,
    };
    let headers = new HttpHeaders().set('Content-Type','application/json');

    return this.http.post(this.baseUrl + '/admin/insertempresa', params, {headers: headers});
  }

  updateEmpresa(empresa,idViejo, image){
    let params = {
      'nombre': empresa.nombre,
      'dominio': empresa.dominio,
      'logo': image,
      'correo': empresa.correo,
      '_id':idViejo
    };
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.http.post(this.baseUrl + '/admin/updateempresa', params, {headers: headers});
  }

  updatePreguntas(listaPreguntas:Pregunta[],numbasicas): Observable<any>{
    let params = {
      'listPreguntas': listaPreguntas,
      'numBasicas': numbasicas
    };
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.http.post(this.baseUrl + '/admin/setnew', params, {headers: headers})
  }

  downloadPDF(id):Observable<any>{
    let params = {
      'id': id
    };
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.http.post(this.baseUrl + '/admin/downloadpdf', params, {headers: headers},);
  }
}
