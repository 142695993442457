import { Component, OnInit } from '@angular/core';
import { PanelService } from '../services/panel/panel.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/login/authentication.service';
import { User } from '../_models/user';
import { Pregunta } from '../_models/pregunta';
import { questionAdminService } from '../services/questions/question.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datacenter',
  templateUrl: './datacenter.component.html',
  styleUrls: ['./datacenter.component.css']
})
export class DatacenterComponent implements OnInit {

  public data: [Pregunta] = null
  currentUser: User;
  title = 'Preguntas';
  pregunta: Pregunta;
  closeResult:string
  numBasicas=4
  dtOptions: DataTables.Settings = {};
  cambio =false
  selection="unica"
  existsInfraccion=false
  listIdiomas=''
  idiomaSelect="es"
  ////
  /// Formulario para insertar nueva pregunta
  newQuestion:Pregunta
  numAnswers = ['','']
  numInfracciones=[null,null]
  numTextoInforme=[null,null]
  listaInfraccion=["Sin infracción", "Grave", "Muy Grave"]
  selInfraccion=['','']
  listaDependencias=[]
  ////
  constructor(
    private questionService: questionAdminService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private panelService:PanelService)
    {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  ngOnInit() {
    this.questionService.getQuestions(this.idiomaSelect).subscribe(
      result => {
        this.data=result
        console.log(this.data)
        if(this.data[0].numBasicas!=undefined){
          this.numBasicas=+this.data[0].numBasicas
        }else{
          this.numBasicas=0
        }
      },
      error => {
      }
    );
    this.questionService.getLanguages().subscribe(
      result => {
        this.listIdiomas=result
      },
      error => {
      }
    );
    this.newQuestion = new Pregunta('', '', '', '', '', '', '', '', '', '', '','')
  }

  onIdiomaSelected(idioma){
    console.log(idioma)
    this.idiomaSelect=idioma
  }
  subir(i){
    this.cambio=true
    let superior = this.data[i-1]
    let actual = this.data[i]
    this.data[i-1]=actual
    this.data[i]=superior
    this.data[i].numQuestion= +this.data[i].numQuestion + 1
    this.data[i-1].numQuestion= +this.data[i].numQuestion - 1

  }

  bajar(i){
    this.cambio=true
    let inferior = this.data[i+1]
    let actual = this.data[i]
    this.data[i+1]=actual
    this.data[i]=inferior
    this.data[i].numQuestion= +this.data[i].numQuestion - 1
    this.data[i+1].numQuestion= +this.data[i].numQuestion + 1
  }

  deleteDependencia(){
    this.listaDependencias.pop()
  }

  addDependencia(){
    this.listaDependencias.push('')
  }
  openXl(content,index) {
    this.modalService.open(content, {size: 'xl', centered: true, scrollable: true});
    this.pregunta = this.data[index];
  }

  keys(triggers) {
    return Object.keys(triggers)
  }

  onDependenciaSelected(value,index){
    console.log('cambio dependencia')
    console.log(value.descrip)
  }
  mas(){
    this.cambio=true
    if(this.numBasicas<this.data.length)
      this.numBasicas++
  }
  menos(){
    this.cambio=true
    if(this.numBasicas>1)
      this.numBasicas--
  }

  save(){
    this.panelService.updatePreguntas(this.data,this.numBasicas).subscribe(r=>{
      this.questionService.getQuestions("es").subscribe(
        result => {
          this.data=result
        },
        error => {
        }
      );
    });
  }

  addAnswer(){
    this.numAnswers.push('')
    this.numInfracciones.push(null)
    this.numTextoInforme.push(null)
    this.selInfraccion.push('')
  }

  deleteAnswer(){
    this.numAnswers.pop()
    this.numInfracciones.pop()
    this.numTextoInforme.pop()
    this.selInfraccion.pop()
  }

  onOptionsSelected(value){
    this.selection=value
  }

  onInfraccionSelected(value){
    this.existsInfraccion=value
  }

  ontypeInfraccionSelected(value,i){
    this.numInfracciones[i]=value
  }
}


