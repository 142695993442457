import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { QuestionsComponent } from './questions/questions.component';
import { SummaryComponent } from './summary/summary.component';
import { ContactComponent } from './contact/contact.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { CompletedComponent } from './completed/completed.component';


const routes: Routes = [

  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', component: WelcomeComponent},
      {path: 'questions', component: QuestionsComponent},
      {path: 'summary', component: SummaryComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'completed', component: CompletedComponent},
      {path: 'download/:token', component: DownloadsComponent},
      {path: '**', redirectTo: ''},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[
  ]

})
export class FormRoutingModule { }
