import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Question } from '../interfaces/question';
import { Log } from '../models/log/log';
import { QuestionsService } from '../services/questions/questions.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { DatosContacto } from '../models/datosContacto/datos-contacto';
import { LanguageService } from '../services/language/language.service';
import { fundido, preguntas } from '../animations/animations'



@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['../styleForm.css'],
  animations: [fundido, preguntas]
})

export class QuestionsComponent implements OnInit {
  Data: Array<any> = [];

  form: FormGroup;

  question: Question;

  //Logs
  log: Log;
  datosContacto: DatosContacto;

  //Totales
  totalQuestions: Number;
  totalQuestionsInfraction: Number;
  // infractionsCount: number;
  infractions: number;

  //Progreso e incumplimiento
  public progress = 0;
  infractionProgress: number;

  //Pregunta anterior
  preguntaAnterior: Number;

  //Error del formulario
  error: boolean;

  //Respuestas
  answers: Array<any> = [];
  answersOrder: Array<Number> = [0];

  textos = null;

  animationState: string = "inactive";

  sanciones=null
  constructor(
    private fb: FormBuilder,
    private questionsService: QuestionsService,
    private router: Router,
    private languageService: LanguageService,
    public zone: NgZone
    ) {
      // this.infractionsCount = 0;
      this.error = false;
      this.progress = 0;
      this.infractionProgress = 0;
      this.preguntaAnterior = 0;
      this.infractions = 0;
      this.datosContacto = new DatosContacto('','','','','');
      this.log = new Log(sessionStorage.getItem('empresa_nombre')!=null?sessionStorage.getItem('empresa_nombre'):'No definido',new Date(),[],sessionStorage.getItem('lang'),0);
    }

  ngOnInit() {

    of(this.questionsService.numQuestions(sessionStorage.getItem('lang')).subscribe(
      result => {
           this.totalQuestions = +result;
      },
    ));

    of(this.questionsService.getSanciones(sessionStorage.getItem('lang')).subscribe(
      result => {
           this.sanciones = result;
      },
    ));

    of(this.questionsService.numQuestionsInfraccion(sessionStorage.getItem('lang')).subscribe(
      result => {
           this.totalQuestionsInfraction = +result;
      },
    ));
    this.languageService.loadMenu(sessionStorage.getItem('lang'),'cuestionario').subscribe(
      result => {
           let resultado = result;
           this.textos = resultado[0].elementos[0];
      },
    );
    this.nextQuestion(1,[]); //Solicitud de la primera pregunta
    this.progress = 0;
  }

  triggerAnimation() {
    this.animationState = "active";
  }
 
  resetAnimation() {
    this.zone.run(() => {
      this.animationState = "inactive";
    });
  }

  submitForm(form) {
      this.triggerAnimation();
      let errors = this.form.controls['checkArray'].errors;
      if(errors==null){
        this.error = false;
        //Guardar pregunta actual y sumar 1
        this.preguntaAnterior = this.question.numQuestion;
        let num = +this.question.numQuestion + 1;
        const checkArray: FormArray = this.form.get('checkArray') as FormArray;

        let infraccion = false;

        //Pregunta de selección única
        if(this.question.select == 'unica'){

          this.answers.push(
            {'question': this.question._id,'answers': [checkArray.value[0]]});

          let i = this.question.answers.indexOf(checkArray.value[0]);
          let infraccionText = null;
          if(+this.question.infraccion.length != 0){
            // this.infractionsCount++;
            if(this.question.infraccion[i]!=null){
              infraccionText = this.question.infraccion[i];
              infraccion = true;
            }
          }
          this.log.respuestas.push({'idPregunta': this.question._id,'respuesta': [checkArray.value[0]],
          'indice':[i],'sancion':infraccionText});
        }
        //Pregunta de selección múltiple
        else{
          let formAnswers: Array<string> = [];
          let respText = [];
          let respIndex = [];
          let infraccionText = null;
          for (let formAnswer of checkArray.value){
            // console.log(this.question);
              formAnswers.push(String.fromCharCode(97 + +formAnswer))
              respText.push(String.fromCharCode(97 + +formAnswer));
              respIndex.push(formAnswer);
              if(this.question.infraccion[formAnswer]!=null){
                infraccionText = this.question.infraccion[formAnswer];
                infraccion = true;
              }
          }
          if(+this.question.infraccion.length != 0){
            // this.infractionsCount++;
          }
          this.answers.push(
            {'question': this.question._id,'answers': formAnswers});
            this.log.respuestas.push({'idPregunta': this.question._id,'respuesta': respText,
          'indice':respIndex,'sancion':infraccionText});
        }

        this.answersOrder.push(this.preguntaAnterior);
        if(infraccion){
          this.infractions = +this.infractions +1;
          this.infractionProgress = Math.round(+this.infractions*100/+this.totalQuestionsInfraction);
        }
        this.nextQuestion(num,this.answers);
      }
      else{
        this.error = true;
      }

  }

  goBack() {
    this.triggerAnimation();
    // if(+this.question.infraccion.length!=0) this.infractionsCount--;
    let respuesta = this.log.respuestas[this.log.respuestas.length-1]
    if(respuesta){
      if(respuesta['sancion'] != null){
        this.infractions = +this.infractions -1;
        this.infractionProgress = Math.round(+this.infractions*100/+this.totalQuestionsInfraction);
      }
      this.log.respuestas.pop();
      this.answers.pop();
      let newQuestion = this.answersOrder.pop();
      this.preguntaAnterior = +this.answersOrder[this.answersOrder.length-1];
      this.previousQuestion(newQuestion,this.question.numQuestion)

    }
    else{
      this.router.navigate([''])
    }

  }

  onRadioChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;

    checkArray.clear();
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.insert(e.target.value,new FormControl(e.target.value))
      // checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  nextQuestion(numQuest: Number, answers: Array<any>) {
    of(this.questionsService.nextQuestion(numQuest,answers,sessionStorage.getItem('lang')).subscribe(
      result => {
           this.question = result.question;
           this.totalQuestionsInfraction = +this.totalQuestionsInfraction-result.saltadas
           if(this.question == null){
            //  if(this.infractionsCount!=0){
            //   // this.infractionProgress = Math.round(+this.infractions*100/+this.infractionsCount);
            //  }
            this.log.porcentajeIncumplimiento = this.infractionProgress;
            let mayorInfraccion=0
            for(let respuesta of this.log.respuestas){

              if(respuesta.sancion!=null){
                let prioridadsancion = this.sanciones[0].sanciones[""+respuesta.sancion+""]
                if(mayorInfraccion<prioridadsancion){
                  mayorInfraccion=prioridadsancion
                }
                if(prioridadsancion==2){
                  break
                }
              }
            }
            sessionStorage.setItem('maxInfraccion', String(mayorInfraccion))
            of(this.questionsService.insertLog(this.log).subscribe(
              result => {
                   sessionStorage.setItem('id_log',result);
                   sessionStorage.setItem('incumplimiento',this.log.porcentajeIncumplimiento.toString());

                   this.router.navigate(['summary']);

              },
              error => {
                  // console.log(<any>error);
              }
            ));

           }
           else {
            this.progress = Math.round(+this.preguntaAnterior*100/+this.totalQuestions);
            //console.log(this.question.numQuestion)
            this.startForm();
           }
      },
      error => {
          //console.log(<any>error);
      }
    ));
  }

  previousQuestion(numQuest: Number, current: Number) {
    of(this.questionsService.previousQuestion(numQuest, current, sessionStorage.getItem('lang')).subscribe(
      result => {
          this.question = result.question;
          this.totalQuestionsInfraction = +this.totalQuestionsInfraction+result.saltadas
          this.progress = Math.round(+this.preguntaAnterior*100/+this.totalQuestions);
          //console.log(this.question.numQuestion)
          this.startForm();

      },
      error => {
          //console.log(<any>error);
      }
    ));
  }

  startForm() {
    this.error = false;
    this.fb= new FormBuilder()
      this.form = new FormGroup({})
      this.Data = [];
      if(this.question.select == 'unica'){
        this.question.answers.forEach(element =>
          this.Data.push(
            {name: 'option', value: element}
          ));
      }
      else{
        this.question.answers.forEach((element, index) =>
          this.Data.push(
            {name: element, value: index}
          ));
      }

      this.form = this.fb.group({
        checkArray: this.fb.array([],[Validators.required])
      });
      // console.log(this.answersOrder)
  }

  restart(){
    let lang = sessionStorage.getItem('lang');
    sessionStorage.clear();
    sessionStorage.setItem('lang',lang);
    this.router.navigate(['']);
  }
}
