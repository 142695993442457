import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormRoutingModule } from '../form/form-routing.module';
import { PanelComponent } from './panel/panel.component';
import {DataTablesModule} from 'angular-datatables';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { DatacenterComponent } from './datacenter/datacenter.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { MainComponent } from './main/main.component';
import { EmpresasComponent } from './empresas/empresas.component';


@NgModule({
  declarations: [LoginComponent, PanelComponent, DatacenterComponent, MainComponent, EmpresasComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    BrowserModule
  ],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class AdminModule { }
