import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './modules/form/welcome/welcome.component';
import { MainComponent } from './modules/form/main/main.component';


const routes: Routes = [
  { path: '',   redirectTo: '/gdpr', pathMatch: 'full' },
  { path: '**', redirectTo: '/gdpr', pathMatch: 'full' }

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
