export class Empresa {
    readonly _id:String;
    nombre:String;
    dominio:String;
    logo:Object;
    correo:String;

    constructor(nombre: String, dominio: String, logo: Object, correo:String){
        this.nombre = nombre;
        this.dominio = dominio;
        this.logo = logo;
        this.correo = correo;
    }
}
