import { Component, OnInit, AfterViewChecked, AfterContentChecked, AfterViewInit } from '@angular/core';

import { DatosContacto } from '../models/datosContacto/datos-contacto';
import { LanguageService } from '../services/language/language.service';
import { Router } from '@angular/router';
import { SummaryService } from '../services/summary/summary.service';
import { fundido } from '../animations/animations'
import { NgCircleProgressModule } from 'ng-circle-progress';



@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css', '../styleForm.css'],
  animations: [fundido]
})
export class SummaryComponent implements OnInit, AfterContentChecked,AfterViewInit {
  closeResult: string;
  datosContacto: DatosContacto;
  privacidad: boolean;
  textos = null;
  id_log: string;
  incumplimiento: number;
  enviado: boolean = false;
  sancion;
  myElement:HTMLElement;
  texto:string
  constructor(
    private router: Router,
    private languageService: LanguageService,
    private summaryService: SummaryService
    ) {
    this.datosContacto = new DatosContacto('','','','','');
    this.privacidad = false;
    this.enviado = false;
    this.myElement = null;
  }

  ngOnInit() {
    this.id_log = sessionStorage.getItem('id_log');
    this.sancion = sessionStorage.getItem('maxInfraccion');
    this.incumplimiento = +sessionStorage.getItem('incumplimiento');

    if(this.id_log == null){
      this.router.navigate(['']);
    }

    console.log(this.sancion)
    this.languageService.loadMenu(sessionStorage.getItem('lang'),'resultado').subscribe(
      result => {
           this.textos = result[0].elementos[0];
           console.log(this.textos)
      },
    );
    setTimeout(() => {
      let collecion = document.getElementsByTagNameNS("http://www.w3.org/2000/svg","svg")
      if(collecion.length==1){
        var arr = Array.prototype.slice.call( collecion )
        this.texto=arr["0"].outerHTML
        this.summaryService.sendGrafico(sessionStorage.getItem('id_log'),this.texto).subscribe(
          result => {
          },
        );
      }
    }, 2000);

  }


  ngAfterContentChecked(){
      this.myElement = document.getElementById("circuloProgreso")
      let collecion = document.getElementsByTagNameNS("http://www.w3.org/2000/svg","svg")
      if(collecion.length==1){
        var arr = Array.prototype.slice.call( collecion )
        this.texto=arr["0"].outerHTML
      }
      /** let collection =this.myElement.getElementsByClassName('ng-star-inserted')
        console.log(collection.item(0)) */


      /**this.summaryService.sendGrafico(sessionStorage.getItem('id_log'),''+this.myElement).subscribe(
        result => {
             this.textos = result;
        },
      );*/
  }

 ngAfterViewChecked(){

 }

ngAfterViewInit(){
    let collecion = document.getElementsByTagNameNS("http://www.w3.org/2000/svg","svg")

    console.log(collecion)
    if(collecion.length==1){
      console.log(collecion)
      var arr = Array.prototype.slice.call( collecion )
      this.texto=arr["0"].outerHTML
      console.log("AFTER")
      console.log(this.texto)
    }

 }
  onSubmit(){
    let id_informe = sessionStorage.getItem('id_log');
    this.summaryService.sendContactData(this.datosContacto,id_informe).subscribe(
      result => {
        this.router.navigate(['completed']);
      });
    this.enviado = true;
    window.scroll(0,0);
  }


  restart(){
    let lang = sessionStorage.getItem('lang');
    sessionStorage.clear();
    sessionStorage.setItem('lang',lang);
    this.router.navigate(['']);
  }

}
