import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Log } from '../../models/log/log';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  nextQuestion(numQuestion: Number, answers, language: string): Observable<any> {

    let params = {
        'numquestion': numQuestion.toString(),
        'arrayanswers': answers,
        'lang': language
    };

    let headers = new HttpHeaders().set('Content-Type','application/json');

    return this.http.post(this.baseUrl + '/next', params, {headers: headers});
  }

  previousQuestion(numQuestion: Number, current: Number, language: string): Observable<any> {

    let params = new HttpParams({ fromObject: {
      'numquestion': numQuestion.toString(),
      'current': current.toString(),
      'lang': language
    }})

    return this.http.get(this.baseUrl + '/before', {params: params});
  }

  numQuestions(language: string) {

    let params = new HttpParams({ fromObject: {
      'lang': language
    }})

    return this.http.get(this.baseUrl + '/total', {params: params});
  }

  numQuestionsInfraccion(language: string) {

    let params = new HttpParams({ fromObject: {
      'lang': language
    }})

    return this.http.get(this.baseUrl + '/totalinfraccion', {params: params});
  }

  insertLog(log: Log): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type','application/json');

    return this.http.post(this.baseUrl + '/logs/insert', log, {headers: headers});
  }

  getSanciones(lang):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');

    let params = new HttpParams({ fromObject: {
      'lang': lang,
    }})

    return this.http.get(this.baseUrl+'/sanctions',{headers: headers, params},);
  }

}
