export class Pregunta {
    answers: [];
    triggers: [[]];
    textoInforme: [];
    infraccion: [];
    _id: String;
    numQuestion: Number ;
    descrip: String;
    help: String;
    lang: String;
    img: String;
    select: string;
    numBasicas: Number;

    constructor(answers, triggers, textoInforme, infraccion, _id, numQuestion, descrip, help, lang, img, select,numBasicas){
        this.answers = answers;
        this.triggers = triggers;
        this.textoInforme = textoInforme;
        this.infraccion = infraccion;
        this.numQuestion = numQuestion;
        this.descrip = descrip;
        this.help = help;
        this.lang = lang;
        this.img = img;
        this.select = select;
        this.numBasicas=numBasicas
    }
}
