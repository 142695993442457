import { animate, state, style, transition, trigger, keyframes} from '@angular/animations';

export const fundido = 
    trigger('fadeIn',[
        state('*',style({
            opacity: 1
        })),
        transition(':enter',[
            style({
                opacity: 0
            }),
            animate('700ms linear')
        ]),
        transition(':leave', [
            animate('500ms linear', style({
                opacity: 0
            }))
        ]),
        state('void', style({
            opacity: 0
        })),
        transition('void <=> *', animate(1000)),
    ]);

    export const preguntas = 
    trigger('pregunta',[
        transition('inactive => active', animate(1000, keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1}),
        ]))),
    ]);
