export class DatosContacto {
    razonSocial:String;
    personaContact:String;
    telefono:String;
    email:String;
    observaciones:String;
    constructor(razonSocial:String, personaContact:String, telefono:String, email:String, observaciones:String){
        this.razonSocial = razonSocial;
        this.personaContact = personaContact;
        this.telefono = telefono;
        this.email = email;
        this.observaciones = observaciones;
    }
}
