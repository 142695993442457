import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient){ }

  descargar(token: string): Observable<any> {

    let params = new HttpParams({ fromObject: {
      'token': token,
    }})

    return this.http.get(this.baseUrl + '/downloadurl', {params: params});
  }
}
