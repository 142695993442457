import { Component, OnInit } from '@angular/core';
import { PanelService } from '../services/panel/panel.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/login/authentication.service';
import { User } from '../_models/user';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  data = null
  empresaSelected="all"
  totalLogs=0
  totalEmpresas=0
  totalInformes=0
  loaded=false
  currentUser: User;
  title = 'Logs';
  listaEmpresas=[]
  tabla:any
  dtOptions: any = {};
  dtInstance = {};
  constructor(
    private panelService: PanelService,
    private router: Router,
    private authenticationService: AuthenticationService)
    {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  ngOnInit() {
    this.panelService.getLogs(this.empresaSelected).subscribe(
      result => {
            this.data=[]
            for(let i of result){
              let email="No definido"
              if(i.datosContacto){
                email=i.datosContacto.email
                this.totalInformes++
              }
              let fech=null
                if(i.fecha){
                  fech=i.fecha.split('T')[0]
                }
              this.totalLogs++
              this.data.push({id:i._id,empresa: i.idEmpresa, fecha: fech, email:email, porcentaje: i.porcentajeIncumplimiento,lang:i.lang})
            }
            this.loaded=true
      },
      error => {
      }
    );
    this.panelService.getTotalEmpresas().subscribe(
      result => {
            this.listaEmpresas = result
            this.totalEmpresas=this.listaEmpresas.length
      },
      error => {
      }
    );

    this.dtOptions = {
      dom: 'Bfrtip',
      // Configure the buttons
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true
    };
  }

    onOptionsSelected(option){
      this.data=null
      this.totalLogs=0
      this.totalInformes=0
      this.loaded=false
      this.empresaSelected=option
      this.panelService.getLogs(option).subscribe(
        result => {
              this.data=[]
              for(let i of result){
                let email="No definido"
                if(i.datosContacto){
                  email=i.datosContacto.email
                  this.totalInformes++
                }
                let fech=null
                if(i.fecha){
                  fech=i.fecha.split('T')[0]
                }
                this.totalLogs++
                this.data.push({id:i._id,empresa: i.idEmpresa, fecha: fech, email:email, porcentaje: i.porcentajeIncumplimiento,lang:i.lang})
              }
              this.loaded=true
        },
        error => {
        }
      );
    }
    descargar(id){
      console.log(id)
      this.panelService.downloadPDF(id).subscribe(
        response => {
          let blob = new Blob([new Uint8Array(response.data)]);
          saveAs(blob, 'informe-'+id+'.pdf');
        }
      )
    }
}
