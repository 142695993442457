import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language/language.service';
import { fundido } from '../animations/animations'
import { WelcomeService } from '../services/welcome/welcome.service';
import { Empresa } from '../interfaces/empresa'
import { Router } from '@angular/router';



@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../styleForm.css'],
  animations: [fundido]
})
export class WelcomeComponent implements OnInit {
  language: String;
  textos = null;


  constructor(
    private languageService: LanguageService,
    private router: Router,
  ) {

  }

  ngOnInit() {

    this.language = sessionStorage.getItem('lang');
    this.languageService.loadMenu(sessionStorage.getItem('lang'),'bienvenida').subscribe(
      result => {
          let resultado = result;
          if(resultado.length!=0){
          this.textos = resultado[0].elementos[0];
          }
      },
    );
  }
  
  ngDoCheck() {
    if(sessionStorage.getItem('lang') !== this.language){
      this.language = sessionStorage.getItem('lang');
      this.languageService.loadMenu(sessionStorage.getItem('lang'),'bienvenida').subscribe(
        result => {
             this.textos = result[0].elementos[0];
            //  console.log(this.textos)
        },
      );
    }
  }
}
