import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DatosContacto } from '../../models/datosContacto/datos-contacto';
@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  sendContactData(datosContacto: DatosContacto, id_informe: string): Observable<any> {

      let headers = new HttpHeaders().set('Content-Type','application/json');
  
      let params = {
        'razonSocial': datosContacto.razonSocial,
        'personaContact': datosContacto.personaContact,
        'telefono': datosContacto.telefono,
        'email': datosContacto.email,
        'observaciones': datosContacto.observaciones,
        'idInforme': id_informe,
      };

      console.log(this.http)
      return this.http.post(this.baseUrl + '/sendMail', params, {headers: headers});
  }

  sendGrafico(id_informe: string, grafico: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-Type','application/json');

    let params = {
      'idInforme': id_informe,
      'grafico': grafico,
    };

    console.log(params)
    return this.http.post(this.baseUrl + '/logs/update', params, {headers: headers});
}

}
