import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormModule } from './modules/form/form.module';
import {AdminModule} from './modules/admin/admin.module';

import { LowerCaseUrlSerializer } from './lowerCaseUrlSerializer';
import { UrlSerializer } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AdminModule,
    FormModule,
    AppRoutingModule
  ],
  providers: [ {
    provide: UrlSerializer,
    useClass: LowerCaseUrlSerializer
  }  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
