import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from  '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormRoutingModule } from './form-routing.module';

import { MainComponent } from './main/main.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { QuestionsComponent } from './questions/questions.component';
import { SummaryComponent } from './summary/summary.component';
import { ContactComponent } from './contact/contact.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { DownloadsComponent } from './downloads/downloads.component';
import { CompletedComponent } from './completed/completed.component';


@NgModule({
  declarations: [MainComponent, WelcomeComponent, QuestionsComponent, SummaryComponent, ContactComponent, DownloadsComponent, CompletedComponent],
  imports: [
    CommonModule,
    FormsModule,
    FormRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    ChartsModule,
    BrowserAnimationsModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      backgroundPadding: -41,
      radius: 55,
      space: -8,
      toFixed: 0,
      maxPercent: 100,
      outerStrokeWidth: 8,
      outerStrokeLinecap: "square",
      titleFontSize: "22",
      titleFontWeight: "700",
      showSubtitle: false,
      showInnerStroke: true,
      innerStrokeColor: "#ccc",
      innerStrokeWidth: 8,
      clockwise: false,
      responsive: true,
      showZeroOuterStroke: false
    })
  ],
  exports: [MainComponent, WelcomeComponent, QuestionsComponent, SummaryComponent, ContactComponent,DownloadsComponent],
  providers: []

})
export class FormModule { }
