import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from './main/main.component'
import {LoginComponent} from './login/login.component'
import { PanelComponent } from './panel/panel.component';
import { AuthGuard } from './_helpers/auth.guard';
import { DatacenterComponent } from './datacenter/datacenter.component';
import { EmpresasComponent } from './empresas/empresas.component';

const routes: Routes = [{
  path: 'admin',
  component:MainComponent,
  children: [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'panel', component: PanelComponent ,canActivate: [AuthGuard]},
    {path: 'datacenter', component: DatacenterComponent ,canActivate: [AuthGuard]},
    {path: 'empresas', component: EmpresasComponent ,canActivate: [AuthGuard]},
    {path: '**', redirectTo: ''},
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
