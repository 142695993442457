import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router"
import { LanguageService } from '../services/language/language.service';
import { switchMap } from 'rxjs/operators';
import { WelcomeService } from '../services/welcome/welcome.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  selectedLanguage: string = 'es';
  languages: Array<any>;
  logo = '';
  empresaNombre = '';
  constructor(
    private router: Router,
    private languageService: LanguageService,
    private route:ActivatedRoute,
    private welcomeService: WelcomeService,
    ) {}

  ngOnInit() {
    let url:string = window.location.href
    let regexp = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/;
    let result = url.match(regexp);
    console.log(result[1])
    let empresa_id = result[1];

    sessionStorage.setItem('empresa_id', empresa_id);

    //console.log(empresa_id)
    let browserLanguage = navigator.language;

    this.languageService.getLanguages().subscribe(
      result => {
          this.languages = result;
          this.languages.forEach(element=>{
            if(element.abbreviation == browserLanguage.toLowerCase().substring(3,5)){
              this.selectedLanguage = browserLanguage.toLowerCase().substring(3,5);
            }
          });
          sessionStorage.setItem('lang',this.selectedLanguage);

      },
      error => {
          //console.log(<any>error);
      }
    );

    if(empresa_id!=null){
      this.welcomeService.getEmpresa(empresa_id).subscribe(
        result => {
          this.logo = result.logo;
          this.empresaNombre = result.nombre;
          sessionStorage.setItem('empresa_nombre',this.empresaNombre);
        },
      );
    }
  }


  checkLanguage(language: string) {
    if (this.selectedLanguage !== language){
      this.selectedLanguage = language;
      sessionStorage.setItem('lang',this.selectedLanguage);
      this.router.navigate(['']);
    }
  }
}
