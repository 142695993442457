import { DatosContacto } from '../datosContacto/datos-contacto'
export class Log {
    idEmpresa:String;
    fecha:Date;
    respuestas: Array<any>;
    lang:String;
    porcentajeIncumplimiento:Number
    
    constructor(
        idEmpresa:String,
        fecha:Date,
        respuestas: Array<any>,
        lang:String,
        porcentajeIncumplimiento:Number){
            this.idEmpresa = idEmpresa;
            this.fecha = fecha;
            this.respuestas = respuestas;
            this.lang = lang;
            this.porcentajeIncumplimiento = porcentajeIncumplimiento;
        }
}
